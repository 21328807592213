import React from 'react'
import HomeModel from '../models/HomeModel'

const Home = () => {
  return (
    <>
      <HomeModel />
    </>
  )
}

export default Home