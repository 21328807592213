import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

const ModalCFDI = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant='h4'>CFDI Versión 4.0</Typography>
      </DialogTitle>
      <DialogContent style={{ fontSize: 14, textAlign: 'justify' }}>
        Por disposición oficial del SAT, a partir del 01 de enero de 2023 las facturas electrónicas serán emitidas bajo la versión 4.0.<br />
        Es importante que los datos de facturación coincidan con la constancia de situación fiscal expedida por el SAT.<br />
        Se puede obtener en: https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal <br />
        Es necesario corroborar que los datos de nombre, razón social, código postal y régimen fiscal coincidan con los que se encuentran en su
        constancia de situación fiscal, esto con el fin de evitar errores al momento de timbrar.<br />
        Nombre y Razón social: Es importante capturar el nombre y ambos apellidos según corresponda la denominación o razón social registrados en
        el RFC del contribuyente receptor del comprobante.<br />
        No se debe incluir las abreviaturas que identifican el tipo de persona moral, por ejemplo: SA de CV, S de RL de CV. A excepción que se incluya
        en la constancia, respetando los espacios que se muestran en esta.
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant='outlined'
          color='primary'
          style={{ width: 200, textTransform: 'none' }}
          onClick={onClose}>Aceptar</Button>
      </DialogActions>

    </Dialog>
  )
}

export default ModalCFDI