import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material'

const ModalFQ = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}>
      <DialogTitle style={{ paddingBottom: 0 }}>Preguntas frecuentes</DialogTitle>
      <DialogContent style={{ maxHeight: 500 }}>
        <List style={{ fontSize: 14 }}>
          <ListItem>
            <ListItemText primary={
              <span style={{ fontSize: 14 }}>
                <strong>¿Qué datos necesito para generar mi factura?</strong> <br />
                Para generar tu factura es necesario contar con los siguientes datos: <br />
                <ul>
                  <li>Folio del ticket</li>
                  <li>Fecha de compra</li>
                  <li>Importe del ticket</li>
                  <li>RFC</li>
                  <li>Nombre o Razón social</li>
                  <li>Uso del CFDI</li>
                  <li>Régimen fiscal</li>
                  <li>Código postal</li>
                </ul>
                Te recordamos que para generar tu factura es necesario que utilices la
                información con la que te encuentras activo en el Registro Federal de
                Contribuyentes.
              </span>
            } />
          </ListItem>
          <ListItem>
            <ListItemText primary={
              <span style={{ fontSize: 14 }}>
                <strong>¿Cuánto tiempo tengo para generar mi factura?</strong><br />
                Únicamente puedes generar tu factura dentro del mes en que realizaste tu compra.
              </span>
            } />
          </ListItem>
          <ListItem>
            <ListItemText primary={
              <span style={{ fontSize: 14 }}>
                <strong>¿Cuánto tiempo tarda en llegar mi factura?</strong><br />
                Tu factura fiscal electrónica (CFDI) deberá llegar al correo electrónico
                especificado en un máximo de 72 horas después de haber sido generada en nuestro portal.
              </span>
            } />
          </ListItem>
          <ListItem>
            <ListItemText primary={
              <span style={{ fontSize: 14 }}>
                <strong>La factura fiscal generada aun no llega a mi correo ¿Qué debo hacer?</strong><br />
                El tiempo de espera es de máximo 72 horas después de que hayas generado tu factura, en caso de no
                recibirla, utilice la opción de consultas y/o comuníquese al <span style={{ fontWeight: 500 }}>contactofacturas@calzzapato.com</span>
              </span>
            } />
          </ListItem>
          <ListItem>
            <ListItemText primary={
              <span style={{ fontSize: 14 }}>
                <strong>¿Puedo generar facturas si realicé una compra con credivale?</strong><br />
                No es posible emitir facturas para las compras realizadas con este método de pago. Si necesita
                aclaración sobre este tema puede comunicarse al correo <span style={{ fontWeight: 500, margin: '0px 2px' }}>contactofacturas@calzzapato.com</span>
                o con la mayorista que le proporcionó el vale.
              </span>
            } />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          style={{ width: 200 }}
          onClick={onClose}><strong>Cerrar</strong></Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalFQ