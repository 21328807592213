import { requestAPI } from "."

const resource = 'billings'
const HOST = "https://api.calzzapato.com/api"

export const billingAPI = {
  validateTicket: async (folio, price) => {
    return await requestAPI({
      host: HOST,
      method: 'GET',
      resource,
      endpoint: `/validate-ticket?folio=${folio}&importe=${price}`
    })
  },
  createBilling: async (data) => {
    return await requestAPI({
      host: HOST,
      method: 'POST',
      resource,
      endpoint: `/generate`,
      data: { data: data }
    })
  },
  getRFCInformation: async (rfc) => {
    return await requestAPI({
      host: HOST,
      method: 'GET',
      resource,
      endpoint: `/getRFCInformation/${rfc}`
    })
  },
  supportRequest: async (data) => {
    return await requestAPI({
      host: HOST,
      method: 'POST',
      resource,
      endpoint: `/support`,
      data: { data: data }
    })
  },
  billingCatalogs: async () => {
    return await requestAPI({
      host: HOST,
      method: 'GET',
      resource,
      endpoint: `/billing-catalogs`
    })
  }
}