import { createContext, useContext, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from './schemas/light';
import { darkTheme } from './schemas/dark';
import { CssBaseline } from '@mui/material';

const ThemeContext = createContext({
  theme: lightTheme,
  toggleTheme: () => { },
});

const themeList = {
  light: lightTheme,
  dark: darkTheme
}

const ThemeProviderContext = ({ children }) => {

  let value = 'light'

  if (typeof window !== 'undefined') {
    const newValue = window.localStorage.getItem('theme')
    if (newValue) {
      value = newValue
    }
  }

  const [theme, setTheme] = useState(themeList[value])

  const toggleTheme = () => {
    const newTheme = theme.palette.mode === 'light' ? darkTheme : lightTheme;
    window.localStorage.setItem('theme', theme.palette.mode === 'dark' ? 'light' : 'dark')
    setTheme(newTheme);
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme
      }} >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider >
  )
}

export const useTheme = () => useContext(ThemeContext)

export default ThemeProviderContext