import CloseIcon from '@mui/icons-material/Close'
const { createContext, useState, useContext } = require("react")
const { Snackbar, IconButton, Alert } = require("@mui/material")


const snackContext = createContext()

const SnackbarContext = ({ children }) => {

  const snackState = {
    open: false,
    message: '',
    error: false
  }

  const [snackBarState, setSnackBarState] = useState({ ...snackState })

  const handleClose = () => setSnackBarState({ ...snackState })
  const handleOpen = (message) => {
    setSnackBarState({
      open: !!message,
      message,
      error: false
    })
  }
  const handleOpenError = (message) => {
    setSnackBarState({
      open: !!message,
      message,
      error: true
    })
  }

  return (
    <snackContext.Provider
      value={{
        openSnackBar: snackBarState,
        showSnackBar: handleOpen,
        showSnackBarError: handleOpenError
      }}
    >
      <Snackbar
        open={snackBarState.open}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <IconButton
            color='inherit'
            onClick={handleClose}><CloseIcon /></IconButton>
        } >
        <Alert onClose={handleClose} severity={snackBarState.error ? 'error' : 'success'} sx={{ width: '100%', borderRadius: 20 }}>
          {snackBarState.message}
        </Alert>
      </Snackbar>
      {children}
    </snackContext.Provider>
  )
}

export const useSnackBar = () => {
  return useContext(snackContext)
}

export default SnackbarContext