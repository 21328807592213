import { Button, CircularProgress, Fade, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import FileUploader from '../../../components/FileUpload'
import { useSnackBar } from '../../../context/SnackbarContext'
import { billingAPI } from '../../../api/billing.api'

const HelpForm = ({ help, setHelp, ...props }) => {

  const initialFile = {
    type: '',
    size: 0,
    width: 0,
    height: 0,
    url: '',
    fileName: ''
  }

  const initialState = {
    ticket: "",
    rfcReceptor: "",
    constanciaBase64: "",
    constanciaNombre: "",
    errorMessage: "",
  }

  const { showSnackBarError, showSnackBar } = useSnackBar()

  const [error, setError] = useState(false)
  const [file, setFile] = useState({ ...initialFile })
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ ...initialState })

  const handleConfirm = async () => {
    setLoading(true)
    try {
      if (file.url) {
        const request = await billingAPI.supportRequest(data)
        if (request.error) {
          throw new Error(request.message)
        }
        showSnackBar(request.message)
        props.handleCancelBilling()
      } else {
        setError(true)
      }
    } catch (error) {
      showSnackBarError(error.message)
    }
    setLoading(false)
  }

  const handleChangeFile = (file) => {
    if (file === null || file === undefined) {
      setFile({
        data: '',
        fileName: '',
        size: 0,
        type: '',
      })
    } else {
      const B64 = file.url.split(',')[1]
      setFile(file)
      setData({
        ticket: props.ticket,
        rfcReceptor: props.rfc,
        constanciaBase64: B64,
        constanciaNombre: file.fileName,
        errorMessage: props.errorMessage,
      })
      setError(false)
    }
  }

  const handleDeleteImage = () => setFile({ ...initialFile })

  return (
    <Fade in={!!(help >= 3)} timeout={1000}>
      <div style={{ padding: 12, textAlign: 'center' }}>
        <Typography variant='body1' sx={{ position: '' }}><strong>Adjunta tu constancia fiscal para poder validar la información y apoyarte con la generación de la factura.</strong></Typography>
        <Stack spacing={2} sx={{ maxWidth: 450, margin: '0px auto', marginTop: 3 }}>
          <FileUploader
            file={file}
            setFile={(file) => handleChangeFile(file)}
            error={error}
            disabled={loading}
            helperText='Es necesario el archivo de la constancia fiscal'
            handleDeleteImage={handleDeleteImage}
            typesAccepted={['images', 'pdf']}
          />
          <Stack direction={'row'} spacing={2}>
            <Button
              fullWidth
              disabled={loading}
              variant='outlined'
              onClick={props.handleCancelBilling}
            >
              Cancelar
            </Button>
            <Button
              disabled={loading}
              fullWidth
              variant='contained'
              onClick={handleConfirm}
            >
              {loading ?
                <CircularProgress
                  size={20}
                />
                :
                'Confirmar'
              }
            </Button>
          </Stack>
        </Stack>
      </div>
    </Fade>
  )
}

export default HelpForm