import axios from 'axios'
import { setupInterceptorsTo } from './interceptors'


export const requestAPI = async (request) => {
  try {
    const data = request.data ?? {}
    const filters = request.filters ? request.filters : ''
    const endpoint = request.endpoint || ''
    const url = `${request.host}/${request.resource}${endpoint}${filters}`
    const headers = {
      'Content-Type': 'application/json',
      'uuid': 'choix',
      ...request.headers,
    }

    const options = {
      method: request.method,
      url,
      data,
      headers,
    }

    const apiInstance = setupInterceptorsTo(axios.create())
    const response = await apiInstance(options)
    return response.data
  } catch (err) {
    return err.response.data
  }
}