import React, { useState } from 'react'
import ModalCFDI from './components/ModalCFDI'
import CheckTicket from './components/CheckTicket';
import BillingForm from './components/BillingForm';
import LeftComponent from './components/LeftComponent';
import ModalFQ from './components/ModalFQ';
import { ButtonBase, Grid, Stack, Typography } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const HomeModel = () => {

  const [showCFDI, setShowCFDI] = useState(true)
  const [validated, setValidated] = useState('')
  const [showFQ, setShowFQ] = useState(false)

  const handleCloseCFDI = () => setShowCFDI(!showCFDI)
  const handleValidated = (ticket) => setValidated(ticket)
  const handleCancelBilling = () => setValidated('')
  const handleShowFQ = () => setShowFQ(!showFQ)

  return (
    <>
      <Grid container alignItems='center' sx={{ marginBottom: { xs: 3, md: 0 } }}>
        <Grid item container sx={{ height: { md: '100vh' }, padding: { xs: 0, md: 6 }, marginTop: { xs: 3, md: -2 } }} alignItems='center' direction='row' spacing={2} xs={12} md={6} order={{ xs: 2, md: 1 }} >
          <LeftComponent />
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Stack spacing={5} alignItems='center'>
            {
              !validated ?
                <CheckTicket
                  validated={validated}
                  handleValidated={handleValidated}
                  handleShowFQ={handleShowFQ}
                />
                :
                <BillingForm
                  validated={validated}
                  handleCancelBilling={handleCancelBilling}
                  handleShowFQ={handleShowFQ}
                />
            }
            <ButtonBase
              sx={{
                color: 'primary.main'
              }}
              onClick={handleShowFQ}
            >
              <QuestionMarkIcon sx={{ height: 18 }} />
              <Typography >Preguntas frecuentes</Typography>
            </ButtonBase>
          </Stack>
        </Grid>
        <ModalCFDI
          open={showCFDI}
          onClose={handleCloseCFDI}
        />
        <ModalFQ
          open={showFQ}
          onClose={handleShowFQ}
        />
      </Grid>
    </>
  )
}

export default HomeModel