import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"

const SuccessModal = ({ open, onClose, email }) => {
  return (
    <Dialog
      open={open}>
      <DialogTitle>
        <Typography variant='h5'>¡Solicitud de factura generada con éxito!</Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <ThumbUpIcon />
        <Typography>
          Su factura fiscal electrónica llegará al correo <br />
          <strong>{email}</strong> en un máximo de 72 horas
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          fullWidth
          variant='contained'
          onClick={onClose}><strong>Aceptar</strong></Button>
      </DialogActions>
    </Dialog>
  )
}

export default SuccessModal