import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Folio from './pages/ticket/folio';
import PolizaGarantia from './pages/polizaGarantia';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/poliza-garantia' element={<PolizaGarantia />} />
      <Route path='/ticket/:id' element={<Folio />} />
    </Routes>
  );
}

export default App;
