import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'

const ModalTicket = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent style={{ width: 400, textAlign: 'center' }}>
        <img src='/ticket.jpg' style={{ maxHeight: 400 }} alt='' />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          fullWidth
          variant='outlined'
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalTicket