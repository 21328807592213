import React, { Component } from 'react'

export class PolizaGarantia extends Component {
  render() {
    return (
      <div style={{ background: "#edeef2" }}>
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', background: '#FFFFFF', fontFamily: 'Arial, sans-serif' }}>
          <div style={{ textAlign: "center", color: "#243B7A", fontWeight: 600, fontSize: 22 }}>PÓLIZA DE GARANTÍA</div>
          <p style={{ textAlign: "justify" }}>
            <p style={{ fontWeight: 550 }}>
              Segutienda S.A de C.V., emite y extiende esta garantía a favor del cliente adquiriente con respecto del(os) artículo(s) adquirido(s) en este ticket, otorgando un lapso de garantía de 90 días a excepción de los casos en los que el fabricante, importador, distribuidor o algún tercero asuma la obligación de garantizar el artículo con una póliza o un escrito, y también salvo en los casos siguientes:
            </p>
            La Garantía no aplica si los datos de la prenda o artículo no corresponden a la factura/ticket.
            <br />
            <br />

            La Garantía no aplica si el artículo ha sufrido daños por uso inapropiado, tales como caídas, raspones, golpes, alteraciones, daños y fallas ocasionados por el mal uso, por fenómenos naturales y si es usado en forma adversa o inapropiada en relación a las condiciones de uso en su instructivo.
            <br />

            La Garantía no puede ser aplicada cuando se trata de mercancía dañada o incompleta, mercancía sin su empaque original, mercancía rebajada o saldos, ropa blanca, ropa interior.
            <br />

            El Término para la garantía correrá a partir del día en que se realiza la adquisición y/o entrega de la mercancía(s).
            <br />

            Artículos con garantía de 90 días o más: todos aquellos artículos en los cuales el proveedor, fabricante, distribuidor o tercero expide una póliza de garantía por un lapso mayor de 90 días hasta un año. Artículos entre los que se encuentran computadoras, celulares, consolas, videojuegos, línea blanca, pantallas, aires acondicionados, entre otros.
            <br />

            Calzado y Prendas de Vestir (ropa): El cliente tiene 15 días para cambios y devoluciones en las áreas de ropa, zapatería, bolsas y carteras por defecto de fabricación, no se aceptan cambios ni devoluciones de ropa interior, perfumería, así como artículos de belleza y cuidado personal.
            <br />

            Demás Condiciones para hacer válida la garantía: Para que se haga válida la garantía, con respecto a la reparación de algún artículo, es necesario presentar este ticket original o factura en la tienda de Grupo Calzapato de adquisicón, presentar empaque o envoltorio original en buen estado, así como no se harán válidas las garantías de artículos modificados.
          </p>
        </div>
      </div>
    )
  }
}

export default PolizaGarantia
