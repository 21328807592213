import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { CardButton } from '../../../components/Styles/Buttons'

const LeftComponent = () => {

  const handleRouter = ({ target: { id } }) => {
    window.open(id)
  }

  return (
    <>
      <Grid item xs={6} md={4}>
        <div style={{ backgroundColor: '#2C2C2C', borderRadius: 6, padding: 26 }}>
          <Typography sx={{ color: 'white' }}>NUEVOS</Typography>
          <Typography sx={{ color: 'white', fontWeight: 600 }} variant='h6'>Mochilas</Typography>
          <Stack spacing={6}>
            <img alt='' src='/ImgMochila.svg' height={100} style={{ marginTop: '12px' }} />
            <CardButton
              id='https://www.calzzapato.com/hombres/accesorios/bolso-deportivo'
              onClick={handleRouter}
              variant='contained' >
              Visitar
            </CardButton>
          </Stack>
        </div>
      </Grid>
      <Grid item xs={6} md={4}>
        <div style={{ backgroundColor: '#CDDE00', borderRadius: 6, padding: 26 }}>
          <Typography sx={{ color: 'white' }}>NUEVOS</Typography>
          <Typography sx={{ color: 'white', fontWeight: 600 }} variant='h6'>Gorras</Typography>
          <Stack spacing={6}>
            <img alt='' src='/gorranegraa 2.svg' height={100} style={{ marginTop: '12px' }} />
            <CardButton
              id='https://www.urbanna.mx/hombres/accesorios/gorra'
              onClick={handleRouter}
              variant='contained' >
              Visitar
            </CardButton>
          </Stack>
        </div>
      </Grid>
      <Grid item xs={6} md={4} >
        <div style={{ backgroundColor: '#E12C34', borderRadius: 6, padding: 26 }}>
          <Typography sx={{ color: 'white' }}>NUEVOS</Typography>
          <Typography sx={{ color: 'white', fontWeight: 600 }} variant='h6'>Tenis</Typography>
          <Stack spacing={6}>
            <img alt='' src='/Tenis.svg' height={100} style={{ marginTop: '12px' }} />
            <CardButton
              id='https://www.calzzapato.com/mujeres/calzado/deportivo'
              onClick={handleRouter}
              variant='contained' >
              Visitar
            </CardButton>
          </Stack>
        </div>
      </Grid>
      <Grid item xs={12} md={8} order={{ xs: 5, md: 4 }} >
        <div style={{
          borderRadius: 6,
          backgroundColor: '#f2f3f3',
          padding: 26
        }}>
          <Stack >
            <Typography color='#233873'>NUEVO</Typography>
            <Stack direction={'row'} justifyContent='space-around' alignItems='center' >
              <Stack>
                <Typography variant='h6' sx={{ fontWeight: 500, color: '#233873' }}>Salas y</Typography>
                <Typography variant='h4' sx={{ fontWeight: 700, color: '#233873' }}>más</Typography>
              </Stack>
              <img alt='' src='/sala.svg' height={200} />
            </Stack>
          </Stack>
          <CardButton
            id='https://www.kelder.mx/departamental/muebles/salas'
            onClick={handleRouter}
            variant='contained' >
            Visitar</CardButton>
        </div>
      </Grid>
      <Grid item xs={6} md={4} order={{ xs: 4, md: 5 }} >
        <div style={{ backgroundColor: '#233873', borderRadius: 6, padding: 26 }}>
          <Typography sx={{ color: 'white' }}>NUEVOS</Typography>
          <Typography sx={{ color: 'white', fontWeight: 600 }} variant='h6'>Calzado kids</Typography>
          <Stack spacing={6}>
            <img alt='' src='/tenisNiño.svg' height={100} style={{ marginTop: '12px' }} />
            <CardButton
              id='https://www.calzakids.mx/search?csa=eyJwZyI6MCwibCI6NDAsInUiOm51bGwsImMiOm51bGwsImJyIjpbXSwic2MiOltdLCJiIjpbXSwiYSI6W10sInMiOltdLCJnIjpbXSwicCI6W10sIm8iOmZhbHNlLCJicCI6ZmFsc2UsInEiOiJ0ZW5pcyJ9'
              onClick={handleRouter}
              variant='contained' >
              Visitar
            </CardButton>
          </Stack>
        </div>
      </Grid>
      <Grid item container xs={12} order={6} spacing={2} justifyContent='center' >
        <Grid item>
          <Stack spacing={2} direction='row'>
            <img alt='' src='/calzzapatov.svg' height={20} />
            <img alt='' src='/kelder_logo.svg' height={20} />
            <img alt='' src='/urbanna_logo.svg' height={20} />
          </Stack>
        </Grid>
        <Grid item >
          <Stack spacing={2} direction='row'>
            <img alt='' src='/calzakidsv.svg' height={20} />
            <img alt='' src='/calzzasportv.svg' height={20} />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default LeftComponent