import { Button, CircularProgress, Fade, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ModalTicket from './ModalTicket';
import { billingAPI } from '../../../api/billing.api';
import { useSnackBar } from '../../../context/SnackbarContext';

const CheckTicket = ({ validated, handleValidated }) => {

  const initialState = {
    folio: '',
    price: ''
  }

  const initialErrors = {
    folio: '',
    price: ''
  }

  const { showSnackBarError, showSnackBar } = useSnackBar()

  const [loading, setLoading] = useState(false)
  const [showTicket, setShowTicket] = useState(false)
  const [state, setState] = useState({ ...initialState })
  const [errors, setErrors] = useState({ ...initialErrors })

  const handleValid = () => {
    const _errors = { ...errors }

    if (!state.folio) {
      _errors.folio = 'El folio es requerido'
    }

    if (!state.price) {
      _errors.price = 'El importe es requerido'
    }

    setErrors(_errors)

    return Object.values(_errors).every(error => !error)
  }

  const handleConfirm = async () => {
    setLoading(true)
    try {
      const isValid = handleValid()
      if (isValid) {

        const validateTicekt = await billingAPI.validateTicket(state.folio, state.price)
        if (validateTicekt.error) {
          throw new Error(validateTicekt.message)
        }
        showSnackBar(validateTicekt.message)
        handleValidated(validateTicekt.data.folioTicket)
      }
    } catch (error) {
      console.log('Error: ', error)
      showSnackBarError(error.message)
    }
    setLoading(false)
  }

  const handleShowTicket = () => setShowTicket(!showTicket)
  const handleValues = ({ target: { id, value } }) => {
    const regex = new RegExp('^[0-9.]*$')
    if (id === 'price') {
      if (regex.test(value)) {
        setState({
          ...state,
          [id]: value
        })
      }
    } else {
      setState({
        ...state,
        [id]: value
      })
    }
    setErrors({
      ...errors,
      [id]: ''
    })
  }

  return (
    <>
      <Fade in={!validated} timeout={1000}>
        <Stack spacing={2} alignItems='center' sx={{
          padding: { xs: 2, md: 0 }
        }}>
          <Typography variant='h5'><strong>Servicio de facturación electrónica</strong></Typography>
          <Stack spacing={1} >
            <Typography variant='body1'>*Únicamente puedes generar tu factura dentro del mes en que realizaste tu compra.</Typography>
            <TextField
              id='folio'
              fullWidth
              required
              value={state.folio}
              disabled={loading}
              onChange={handleValues}
              variant='standard'
              placeholder='Ingresa el folio del ticket'
              error={errors.folio}
              helperText={errors.folio}
              InputProps={{
                disableUnderline: true,
                sx: {
                  backgroundColor: 'inputBack.main',
                  border: `solid ${errors.folio ? '1px' : '0px'} #f44336`,
                  borderRadius: 2,
                  padding: '0px 12px',
                  height: 40
                },
                endAdornment:
                  <IconButton
                    onClick={handleShowTicket}
                  >
                    <HelpOutlineIcon color='primary' />
                  </IconButton>
              }}
            />
            <TextField
              id='price'
              fullWidth
              required
              value={state.price}
              disabled={loading}
              onChange={handleValues}
              error={errors.price}
              helperText={errors.price}
              variant='standard'
              placeholder='Importe de compra'
              InputProps={{
                disableUnderline: true,
                sx: {
                  backgroundColor: 'inputBack.main',
                  border: `solid ${errors.price ? '1px' : '0px'} #f44336`,
                  borderRadius: 2,
                  padding: '0px 12px',
                  height: 40
                },
              }}
            />
          </Stack>
          <Button
            fullWidth
            size='small'
            disabled={loading}
            variant='outlined'
            onClick={handleConfirm}
          > {loading ?
            <CircularProgress
              size={20}
            />
            :
            'Confirmar'
            }</Button>
        </Stack>
      </Fade>
      <ModalTicket
        open={showTicket}
        onClose={handleShowTicket}
      />
    </>
  )
}

export default CheckTicket