import { Button, CircularProgress, Fade, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackBar } from '../../../context/SnackbarContext'
import HelpForm from './HelpForm'
import { billingAPI } from '../../../api/billing.api'
import SuccessModal from './SuccessModal'

const BillingForm = ({ validated, handleCancelBilling }) => {

  const { showSnackBarError, showSnackBar } = useSnackBar()
  const [loading, setLoading] = useState(false)

  const [help, setHelp] = useState(0)
  const [CFDIs, setCFDIs] = useState([{ id: 0, code: '0', description: 'Cargando...' }])
  const [regimenesFiscales, setRegimenesFiscales] = useState([{ id: 0, code: 0, description: 'Cargando...' }])
  const [sowSucces, setSowSucces] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const getCatalogs = async () => {
      try {
        const { success, cfdi, regimenFiscal } = await billingAPI.billingCatalogs()
        if (success) {
          setCFDIs(cfdi)
          setRegimenesFiscales(regimenFiscal)
        }
      } catch (error) {
        showSnackBarError(error.message)
      }
    }

    getCatalogs()
  }, [])

  const validationSchema =
    Yup.object({
      rfc: Yup.string().min(12, 'RFC no válido.').required('Campo obligatorio.'),
      name: Yup.string().max(64).required('Campo obligatorio.'),
      regime: Yup.number().test('Invalid number', 'Campo obligatorio', (value) => value),
      cfdi: Yup.string().test('Invalid number', 'Campo obligatorio', (value) => value !== '0'),
      zip: Yup.string().min(5, 'Código postal no válido.').max(5, 'Código postal no válido. +').required('Campo obligatorio.'),
      email: Yup.string().email('Correo electrónico no válido.').max(64).required('Campo obligatorio.'),
      cellphone: Yup.string().min(10, 'Número telefonico no válido.').max(10, 'Número telefonico no válido.').required('Campo obligatorio.')
    })

  const formik = useFormik({
    initialValues: {
      receptorId: 0,
      rfc: '',
      name: '',
      regime: 0,
      cfdi: '0',
      zip: '',
      email: '',
      cellphone: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleConfirm(values)
    }
  })

  const handleDownloadPDF = (pdf) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `factura-${validated}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click()
  }
  const handleDownloadXML = (xml) => {
    const linkSource = `data:application/xml;base64,${xml}`;
    const downloadLink = document.createElement("a");
    const fileName = `factura-${validated}.xml`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click()
  }

  const handleConfirm = async (values) => {
    setLoading(true)
    let data = {
      receptor: {
        id: values.receptorId,
        rfc: values.rfc,
        nombre: values.name,
        codigoPostal: values.zip,
        regimenFiscal: values.regime.toString(),
        usoCFDI: values.cfdi,
        correo: values.email,
        telefono: values.cellphone
      },
      ticket: validated,
    }

    try {
      const createBilling = await billingAPI.createBilling(data)
      if (createBilling.error) {
        throw new Error(createBilling.message)
      }
      handleDownloadPDF(createBilling.data.pdfBase64)
      handleDownloadXML(createBilling.data.xmlBase64)
      setSowSucces(true)
      showSnackBar(createBilling.message)
    } catch (error) {
      showSnackBarError(error.message)
      setErrorMessage(error.message)
      setHelp(help + 1)
    }
    setLoading(false)
  }

  const getRFCInformation = async (rfc) => {
    setLoading(true)
    try {

      const request = await billingAPI.getRFCInformation(rfc)
      if (!request.success) {
        throw new Error('Error al obtener los datos mediante rfc')
      }
      formik.setValues({
        receptorId: request.information.id,
        rfc: request.information.rfc,
        name: request.information.nombre,
        regime: Number(request.information.regimenFiscal),
        cfdi: request.information.usoCFDI,
        zip: request.information.codigoPostal,
        email: request.information.correo,
        cellphone: request.information.telefono
      })

    } catch (error) {
      console.log('Error: ', error.message)
    }
    setLoading(false)
  }

  const handleChangeRFC = async ({ target: { value } }) => {
    if (value.length === 12 || value.length === 13) {
      await getRFCInformation(value)
    }
  }

  const handleNumberCheck = (e) => {
    const regex = new RegExp('^[0-9]*$')
    if (regex.test(e.target.value)) {
      formik.handleChange(e)
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {
        help < 3 ?
          <Fade in={!!(help < 3)} timeout={1000}>
            <Stack spacing={3}  >
              <Typography variant='h6' style={{ margin: '0px auto' }}><strong>Servicio de Facturación electrónica</strong></Typography>
              <Stack spacing={1.2} sx={{ width: { xs: 350, md: 450 } }}>
                <InputLabel style={{ fontSize: 14 }} required>RFC</InputLabel>
                <TextField
                  name='rfc'
                  disabled={loading}
                  fullWidth
                  placeholder='Persona física (13 dígitos) / Moral (12 dígitos)'
                  variant='standard'
                  error={formik.touched.rfc && formik.errors.rfc}
                  helperText={formik.touched.rfc && formik.errors.rfc}
                  value={formik.values.rfc}
                  onChange={formik.handleChange}
                  onBlur={handleChangeRFC}
                  inputProps={{ maxLength: 13 }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      backgroundColor: 'inputBack.main',
                      marginTop: -1,
                      borderRadius: 2,
                      padding: '0px 12px',
                      height: 40,
                      border: `solid ${formik.touched.rfc && formik.errors.rfc ? '1px' : '0px'} #f44336`
                    }
                  }}
                />
                <InputLabel style={{ fontSize: 14 }} required>Razón social</InputLabel>
                <TextField
                  name='name'
                  fullWidth
                  disabled={loading}
                  placeholder='Ingresa la razón social'
                  variant='standard'
                  value={formik.values.name}
                  error={formik.touched.name && formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      backgroundColor: 'inputBack.main',
                      marginTop: -1,
                      borderRadius: 2,
                      padding: '0px 12px',
                      height: 40,
                      border: `solid ${formik.touched.name && formik.errors.name ? '1px' : '0px'} #f44336`
                    }
                  }}
                />
                <InputLabel style={{ fontSize: 14 }} required>Código postal</InputLabel>
                <TextField
                  name='zip'
                  fullWidth
                  disabled={loading}
                  placeholder='Ingresa tu código postal'
                  variant='standard'
                  value={formik.values.zip}
                  error={formik.touched.zip && formik.errors.zip}
                  helperText={formik.touched.zip && formik.errors.zip}
                  onChange={handleNumberCheck}
                  inputProps={{ maxLength: 5 }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      backgroundColor: 'inputBack.main',
                      marginTop: -1,
                      borderRadius: 2,
                      padding: '0px 12px',
                      height: 40,
                      border: `solid ${formik.touched.zip && formik.errors.zip ? '1px' : '0px'} #f44336`
                    }
                  }}
                />
                <InputLabel style={{ fontSize: 14 }} required>Correo electrónico</InputLabel>
                <TextField
                  name='email'
                  fullWidth
                  disabled={loading}
                  placeholder='Ingresa tu correo electrónico'
                  variant='standard'
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      backgroundColor: 'inputBack.main',
                      marginTop: -1,
                      borderRadius: 2,
                      padding: '0px 12px',
                      height: 40,
                      border: `solid ${formik.touched.email && formik.errors.email ? '1px' : '0px'} #f44336`
                    }
                  }}
                />
                <InputLabel style={{ fontSize: 14 }} required>Número telefónico</InputLabel>
                <TextField
                  name='cellphone'
                  fullWidth
                  disabled={loading}
                  placeholder='Ingresa tu número telefónico'
                  variant='standard'
                  value={formik.values.cellphone}
                  error={formik.touched.cellphone && formik.errors.cellphone}
                  helperText={formik.touched.cellphone && formik.errors.cellphone}
                  onChange={handleNumberCheck}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      backgroundColor: 'inputBack.main',
                      marginTop: -1,
                      borderRadius: 2,
                      padding: '0px 12px',
                      height: 40,
                      border: `solid ${formik.touched.cellphone && formik.errors.cellphone ? '1px' : '0px'} #f44336`
                    }
                  }}
                />
                <InputLabel style={{ fontSize: 14 }} required>Régimen fiscal</InputLabel>
                <FormControl>
                  <Select
                    name='regime'
                    value={formik.values.regime}
                    disabled={loading}
                    error={formik.errors.regime && formik.touched.regime}
                    onChange={formik.handleChange}
                    input={
                      <TextField
                        select
                        variant='standard'
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            backgroundColor: 'inputBack.main',
                            marginTop: -1,
                            borderRadius: 2,
                            padding: '0px 12px',
                            height: 40,
                            border: `solid ${formik.touched.regime && formik.errors.regime ? '1px' : '0px'} #f44336`
                          }
                        }}
                      />
                    }
                  >
                    <MenuItem disabled value={0}>Selecciona régimen fiscal</MenuItem>
                    {
                      regimenesFiscales && regimenesFiscales.map(item => (
                        <MenuItem key={item.id} value={item.code}>{item.code + ' - ' + item.description}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText sx={{ color: 'error.main' }}>{formik.touched.regime && formik.errors.regime}</FormHelperText>
                </FormControl>
                <InputLabel style={{ fontSize: 14 }} required>CFDI</InputLabel>
                <FormControl>
                  <Select
                    name='cfdi'
                    disabled={loading}
                    value={formik.values.cfdi}
                    error={formik.errors.cfdi && formik.touched.cfdi}
                    onChange={formik.handleChange}
                    input={
                      <TextField
                        select
                        variant='standard'
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            backgroundColor: 'inputBack.main',
                            marginTop: -1,
                            borderRadius: 2,
                            padding: '0px 12px',
                            height: 40,
                            border: `solid ${formik.touched.cfdi && formik.errors.cfdi ? '1px' : '0px'} #f44336`
                          }
                        }}
                      />
                    }
                  >
                    <MenuItem disabled value={'0'}>Selecciona un uso CFDI</MenuItem>
                    {
                      CFDIs && CFDIs.map(item => (
                        <MenuItem key={item.id} value={item.code}>{item.code + ' - ' + item.description}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText sx={{ color: 'error.main' }}>{formik.touched.cfdi && formik.errors.cfdi}</FormHelperText>
                </FormControl>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant='outlined'
                  onClick={handleCancelBilling}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={loading}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  {loading ?
                    <CircularProgress
                      size={20}
                    />
                    :
                    'Confirmar'
                  }
                </Button>
              </Stack>
            </Stack>
          </Fade>
          :
          <HelpForm
            ticket={validated}
            errorMessage={errorMessage}
            rfc={formik.values.rfc}
            help={help}
            handleCancelBilling={handleCancelBilling}
            setHelp={setHelp}
          />
      }
      <SuccessModal
        open={sowSucces}
        onClose={handleCancelBilling}
        email={formik.values.email}
      />
    </form >
  )
}

export default BillingForm