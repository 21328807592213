import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardButton = styled(Button)({
  borderRadius: 50,
  fontSize: 14,
  color: 'red',
  backgroundColor: 'white',
  fontWeight: 700,
  maxWidth: 100,
  minWidth: 100,
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
  ':hover': {
    backgroundColor: '#d1d1d1',
  }
})